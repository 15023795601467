import { useLayoutEffect } from 'preact/hooks';
import { initHomepageStore, sendHomepageAnalytics, sendHomepageImpression } from 'lib/dot/homepage/utils.ts';
import { DOTHelper } from 'lib/dot/DOTHelper.ts';
import { PageType } from 'lib/dot/enum/pageType.ts';

export type Props = { offersCount: number };

export function DotHomepage({ offersCount }: Props) {
	useLayoutEffect(() => {
		DOTHelper.initPageType(PageType.HOMEPAGE);
		// Set up search store for search analytics. Required for analytics when working with saved searches tab.
		initHomepageStore({
			myProfile: false,
			sellerFilter: false,
			usedGoods: false,
		});

		sendHomepageImpression();
		sendHomepageAnalytics(offersCount);
	}, []);
}
